import { Console } from 'console';
import { Component, ElementRef, OnInit,Input, ViewChild     } from '@angular/core';
import { Router,ActivatedRoute                              } from '@angular/router';
import { AlertController, ToastController,LoadingController, ModalController } from '@ionic/angular';
import { Storage                                            } from '@ionic/storage-angular';
import { UsuarioService                                     } from '../service/domain/usuario.service';
import { EnderecoCadastroPage                               } from '../endereco-cadastro/endereco-cadastro.page';
import { BuscacepPage                                       } from '../buscacep/buscacep.page';
import { EnderecosPage } from '../enderecos/enderecos.page';

@Component({
  selector   : 'app-cadastro-endereco',
  templateUrl: './cadastro-endereco.page.html',
  styleUrls  : ['./cadastro-endereco.page.scss'],
})
export class CadastroEnderecoPage implements OnInit {
  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/type-annotation-spacing
  CLIENTE                   : any;
  private _storage: Storage | null = null;

 // eslint-disable-next-line @typescript-eslint/member-ordering
 @ViewChild('input') myInput;

  // eslint-disable-next-line @typescript-eslint/member-ordering, @typescript-eslint/type-annotation-spacing
  endereco      : any;
    // eslint-disable-next-line @typescript-eslint/member-ordering, @typescript-eslint/type-annotation-spacing
  data          : any;
    // eslint-disable-next-line @typescript-eslint/member-ordering, @typescript-eslint/type-annotation-spacing
  cep           : any;
    // eslint-disable-next-line @typescript-eslint/member-ordering, @typescript-eslint/type-annotation-spacing
  logradouro    : any;
    // eslint-disable-next-line @typescript-eslint/member-ordering, @typescript-eslint/type-annotation-spacing
  complemento   : any;
    // eslint-disable-next-line @typescript-eslint/member-ordering, @typescript-eslint/type-annotation-spacing
  bairro        : any;
    // eslint-disable-next-line @typescript-eslint/member-ordering, @typescript-eslint/type-annotation-spacing
  localidade    : any;
    // eslint-disable-next-line @typescript-eslint/member-ordering, @typescript-eslint/type-annotation-spacing
  uf            : any;
    // eslint-disable-next-line @typescript-eslint/member-ordering, @typescript-eslint/type-annotation-spacing
  ibge          : any;
    // eslint-disable-next-line @typescript-eslint/member-ordering, @typescript-eslint/type-annotation-spacing
  gia           : any;
    // eslint-disable-next-line @typescript-eslint/member-ordering, @typescript-eslint/type-annotation-spacing
  ddd           : any;
    // eslint-disable-next-line @typescript-eslint/member-ordering, @typescript-eslint/type-annotation-spacing
  siafi         : any;
    // eslint-disable-next-line @typescript-eslint/member-ordering, @typescript-eslint/type-annotation-spacing
  referencia    : any;
    // eslint-disable-next-line max-len
    // eslint-disable-next-line @typescript-eslint/member-ordering, @typescript-eslint/type-annotation-spacing, @typescript-eslint/no-inferrable-types
  numero        : string = '';
    // eslint-disable-next-line max-len
    // eslint-disable-next-line @typescript-eslint/member-ordering, @typescript-eslint/type-annotation-spacing, @typescript-eslint/naming-convention
  id_cliente    : any;
    // eslint-disable-next-line @typescript-eslint/member-ordering, @typescript-eslint/type-annotation-spacing
  cliente       : any;
    // eslint-disable-next-line max-len
    // eslint-disable-next-line @typescript-eslint/member-ordering, @typescript-eslint/type-annotation-spacing, @typescript-eslint/naming-convention
  busca_cep     : boolean;
    // eslint-disable-next-line max-len
    // eslint-disable-next-line @typescript-eslint/member-ordering, @typescript-eslint/type-annotation-spacing, @typescript-eslint/naming-convention
  nao_sei_cep   : boolean;
    // eslint-disable-next-line max-len
    // eslint-disable-next-line @typescript-eslint/member-ordering, @typescript-eslint/type-annotation-spacing, @typescript-eslint/naming-convention
  endereco_BUSCA: any;
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/member-ordering, @typescript-eslint/naming-convention, @typescript-eslint/type-annotation-spacing
   LOJA                       : any;
  // eslint-disable-next-line @typescript-eslint/member-ordering, @typescript-eslint/type-annotation-spacing
  @Input() navigationExtras      : any;
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/member-ordering, @typescript-eslint/naming-convention, @typescript-eslint/type-annotation-spacing
  @Input() PGenderecos_cad       : EnderecoCadastroPage;
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/member-ordering, @typescript-eslint/naming-convention, @typescript-eslint/type-annotation-spacing
  @Input() PGbuscaCep            : BuscacepPage;
  // eslint-disable-next-line max-len
  // eslint-disable-next-line space-before-function-paren, @typescript-eslint/member-ordering, @typescript-eslint/type-annotation-spacing, @typescript-eslint/naming-convention
  @Input() Pgenderecos           : EnderecosPage;
  // eslint-disable-next-line space-before-function-paren
  constructor
  (
    // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
    private storage            : Storage,
     // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
    private router             : Router,
     // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
    private actRouter          : ActivatedRoute,
     // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
    private usuario            : UsuarioService,
     // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
    public  toast              : ToastController,
     // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
    public  alertController    : AlertController,
     // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
    private service            : UsuarioService,
     // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
    private loadingCtrl        : LoadingController,
    // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
    private modalController    : ModalController,

  ) {
    this.init();


  }

  async showLoading() {
    const loading = await this.loadingCtrl.create({
      message  : 'Carregando...',
      duration : 1000,
      spinner  : 'circles',
    });

    loading.present();
  }



  async init() {

    const storage = await this.storage.create();
    // eslint-disable-next-line no-underscore-dangle
    this._storage = storage;
  }


  async ngOnInit()
  {


        this.endereco        = this.navigationExtras.state.endereco;
        this.cep             = this.navigationExtras.state.cep;
        this.logradouro      = this.navigationExtras.state.logradouro;
        this.complemento     = this.navigationExtras.state.complemento;
        this.bairro          = this.navigationExtras.state.bairro;
        this.localidade      = this.navigationExtras.state.localidade;
        this.uf              = this.navigationExtras.state.uf;
        this.ibge            = this.navigationExtras.state.ibge;
        this.gia             = this.navigationExtras.state.gia;
        this.ddd             = this.navigationExtras.state.ddd;
        this.siafi           = this.navigationExtras.state.siafi;
        this.referencia      = this.navigationExtras.state.referencia;
        this.id_cliente      = this.navigationExtras.state.id;
        this.numero          = this.navigationExtras.state.numero;

        const loja           = await this.storage.get('LOJA');
        this.LOJA            = loja;

  }

  home() {
    this.Pgenderecos.ListarEndereços(this.Pgenderecos.id_cliente);
    this.PGenderecos_cad.modalController.dismiss();
    this.modalController.dismiss();
  }
  voltar(id){

    this.Pgenderecos.ListarEndereços(this.Pgenderecos.id_cliente);
    this.PGenderecos_cad.modalController.dismiss();

    this.modalController.dismiss();
  }
  async ionViewWillEnter() {
    this.cliente = [];
    const name   = await this.storage.get('DADOS');
    this.cliente = name;

    setTimeout(() => {
      this.myInput.setFocus();
    },200);

  }


  // eslint-disable-next-line @typescript-eslint/naming-convention
  async CADASTRO_ENDERECO(CEP,LOGRADOURO,NUMERO,CIDADE,BAIRRO,COMPLEMENTO,REFERENCIA,CLIENTE)
{



  // eslint-disable-next-line @typescript-eslint/quotes, eqeqeq
  if(REFERENCIA == "")
  {
    const alert = await this.alertController.create({
      header          : 'REFERENCIA é Obrigatória !!',
      backdropDismiss : false,
      buttons         : [
         {
          text: 'ok',
          handler: (data) => {

          }
        }
      ]
    });

    await alert.present();
    return;
  };

  // eslint-disable-next-line eqeqeq, @typescript-eslint/quotes
  if(COMPLEMENTO == "")
  {
    const alert = await this.alertController.create({
      header          : 'COMPLEMENTO é Obrigatório !!',
      backdropDismiss : false,
      buttons         : [
         {
          text        : 'ok',
          handler     : (data) => {

          }
        }
      ]
    });

    await alert.present();
    return;
  };
  // eslint-disable-next-line eqeqeq, @typescript-eslint/quotes
  if(COMPLEMENTO == "undefined")
  {
    const alert = await this.alertController.create({
      header          : 'COMPLEMENTO é Obrigatório !!',
      backdropDismiss : false,
      buttons         : [
         {
          text        : 'ok',
          handler     : (data) => {

          }
        }
      ]
    });

    await alert.present();
    return;
  };


  // eslint-disable-next-line eqeqeq, @typescript-eslint/quotes
  if(this.numero == "")
  {
    const alert = await this.alertController.create({
      header           : 'Nº é Obrigatório !!',
      backdropDismiss  : false,

      buttons          : [
         {
          text         : 'ok',
          handler      : (data) => {

          }
        }
      ]
    });

    await alert.present();
  }
  else
  {

  this.showLoading();

  this.usuario.getcadastroENDERECO
  (
    CEP,
    LOGRADOURO,
    NUMERO,
    CIDADE,
    BAIRRO,
    COMPLEMENTO,
    REFERENCIA,
    CLIENTE,
    this.LOJA.EMPRESA,
    this.localidade
    ).subscribe(async data =>
    {

      // eslint-disable-next-line no-var, @typescript-eslint/dot-notation
      var alert   = data['MSG'];

     // eslint-disable-next-line eqeqeq
     if( alert == 'CEP JÁ CADASTRADO!'){
      this.msg(alert,'danger');
     }else
     {
      console.log(alert);
     // this.msg(alert,'success');
     }

      this.voltar(0);

    }

    );


  }

}
// eslint-disable-next-line @typescript-eslint/naming-convention
public ListarEndereços(CLIENTE) {
  this.service.getENDERECO(CLIENTE,this.LOJA.EMPRESA).subscribe(async data => {

    // eslint-disable-next-line @typescript-eslint/dot-notation, prefer-const
    for (let item of data['result']) {

      await this.storage.remove('ENDERECOS');
      // eslint-disable-next-line @typescript-eslint/dot-notation
      await this.storage.set('ENDERECOS', data['result']);
      this.voltar(CLIENTE);
    }

  });

}


async msg(msg,tipo){

  const toast = await this.toast.create({
    message  : msg,
    duration : 1800,
    position : 'top',
    color    : tipo
  });
  toast.present();


}

}
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
function ionViewLoaded() {
  throw new Error('Function not implemented.');
}

