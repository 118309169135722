/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/naming-convention */

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ServidorService } from '../servidor.service';
// informações da empresa
export interface Empresa{
            // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/type-annotation-spacing
            FANTASIA      : string;
            // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/type-annotation-spacing
            CIDADE        : string;
            // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/type-annotation-spacing
            UF            : string;
            // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/type-annotation-spacing
            CIDADE_       : string;
            // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/type-annotation-spacing
            UF_           : string;

}
// informações da categoria
   // eslint-disable-next-line @typescript-eslint/naming-convention
   export interface categoria{
  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/type-annotation-spacing
  nome_cat   : string;
  // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
  id         : string;
  // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
  OBS_GRUPO  : string;
  // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
  QTD_MINIMA : string;
   }
   // informações dos produtos relacionados
   export interface ProdutosRel{
    // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
    nome_item         : string;
    // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
    descricao_item    : string;
    // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
    id                : number;
    // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
    img_item          : string;
    // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
    preco_item        : string;
    // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
    id_cat            : string;
    // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
    composicao        : string;
    // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
    quantidade        : number;
    // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/ban-types
    Grupo_item        : String;
    // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/ban-types
    IMAGEM            : String;
    // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/ban-types
    composicao_resumo : String;
    // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
    img               : any;
    // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
    foto              : string;
    }

     // informações dos horarios
   export interface horario{
    // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/member-delimiter-style
     DIA_SEMANA :string,
     // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/member-delimiter-style
     HR_ABRE    :string,
     // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/member-delimiter-style
     HR_FECHA   :string,
     }

       // informações dos horarios
   export interface PAGAMENTO{
    // eslint-disable-next-line @typescript-eslint/member-delimiter-style, @typescript-eslint/type-annotation-spacing
    DESCRICAO :string,

    }
    export interface resposta{
      // eslint-disable-next-line @typescript-eslint/member-delimiter-style, @typescript-eslint/type-annotation-spacing
      DESCRICAO :string,

      }
  export interface pix{

  }


@Injectable({
  providedIn: 'root'
})


export class DatabaseService {



  constructor(
    private http: HttpClient,
    private config: ServidorService
  ) { }

  // listar empresa
   getEmpresa(){

    return this.http.get<Empresa[]>(`${this.config.baseUrl}/logista?EMPRESA=`+this.config.EMPRESALOG);
   }
  // listar categoria da empresa
  GetGrupo(){
    return this.http.get<categoria[]>(`${this.config.baseUrl}/GRUPO/`);
  }
  // listar nome da categoria
  getCategoria_id(id){

       return this.http.get<[categoria]>(this.config.baseUrl + '/ProdutoRel/' + id);
  }
     // listar produtos da categoria
  // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
  getProdutoRelacionado(id : any){

    return this.http.get<ProdutosRel[]>(`${this.config.baseUrl}/ProdutoRel?ID=${id}`);
  }
   // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
   getIdCat(id:any){

       return this.http.get<[categoria]>(this.config.baseUrl + '/' + id);
     }
horario()
{

 return this.http.get<[horario]>(this.config.baseUrl + '/HORARIO');
}

PAGAMENTO()
{
       return this.http.get<[PAGAMENTO]>(this.config.baseUrl + '/PAGAMENTO');
}

valida_horario()
{

          return this.http.get<[resposta]>(this.config.baseUrl + '/VALIDA_HORARIO');
}
GerarPix(VALOR,CLIENTE,PEDIDO)
{
  return this.http.get<[]>(this.config.baseUrl + '/GERARPIX?VALOR='+VALOR+'&CLIENTE='+CLIENTE+'&PEDIDO='+PEDIDO);
}

GerarConsultaPix(CLIENTE,HASH)
{
  return this.http.get<[]>(this.config.baseUrl + '/CONSULTAPIX?PEDIDO='+CLIENTE+'&HASH='+HASH);
}
UpdantePedidoPix(CLIENTE)
{
  return this.http.get<[]>(this.config.baseUrl + '/MUDARPEDIDOPIX?CLIENTE='+CLIENTE);
}

Agendamento(EMPRESA,TIPO){

  return this.http.get<[]>(`${this.config.baseUrl}/AGENDAMENTO?EMPRESA=`+EMPRESA+`&TIPO=`+TIPO);
 }

 Feriado(){

  return this.http.get<[]>(`${this.config.baseUrl}/FERIADOS`);
 }
}


