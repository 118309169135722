import { Component, Input, OnInit                        } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Storage                                  } from '@ionic/storage-angular';
import { UsuarioService                           } from '../service/domain/usuario.service';

import { EnderecosPage                            } from '../enderecos/enderecos.page';
import { ModalController, ToastController         } from '@ionic/angular';
import { Geolocation                              } from '@awesome-cordova-plugins/geolocation/ngx';
import { CadastroEnderecoPage                     } from '../cadastro-endereco/cadastro-endereco.page';
import { BuscacepPage                             } from '../buscacep/buscacep.page';


interface Endereco {
  // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
  endereco : string;
  // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
  numero   : string;
  // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
  bairro   : string;
  // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
  cidade   : string;
  // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
  estado   : string;
  // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
  cep      : string;
  // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
  pais     : string;
}


@Component({
  selector    : 'app-endereco-cadastro',
  templateUrl : './endereco-cadastro.page.html',
  styleUrls   : ['./endereco-cadastro.page.scss'],
})
export class EnderecoCadastroPage implements OnInit {
  // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
  endereco      : any;
   // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
  data          : any;
   // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
  cep           : any;
   // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
  logradouro    : any;
   // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
  complemento   : any;
   // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
  bairro        : any;
   // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
  localidade    : any;
   // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
  uf            : any;
   // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
  ibge          : any;
   // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
  gia           : any;
   // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
  ddd           : any;
   // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
  siafi         : any;
   // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
  referencia    : any;
   // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
  numero        : any;
   // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/naming-convention
  id_cliente    : any;
   // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
  cliente       : any;
   // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
  dados         : any;
   // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/naming-convention
  busca_cep     : boolean;
   // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/naming-convention
  nao_sei_cep   : boolean;
   // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/no-inferrable-types, id-blacklist
  string        : boolean = false;
   // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/naming-convention
  endereco_BUSCA: any;
  private _storage: Storage | null = null;
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/member-ordering, @typescript-eslint/naming-convention, @typescript-eslint/type-annotation-spacing
  @Input() LOJA               : any;
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/member-ordering, @typescript-eslint/naming-convention, @typescript-eslint/type-annotation-spacing
  @Input() PGbuscaCep         : BuscacepPage;
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/member-ordering, @typescript-eslint/naming-convention, @typescript-eslint/type-annotation-spacing
  @Input() Pgenderecos        : EnderecosPage;


  constructor(
    // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
    private storage        : Storage,
    // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
    private router         : Router,
    // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
    private usuario        : UsuarioService,
    // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
    private actRouter      : ActivatedRoute,
    // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
    public  pagnaEndereco  : EnderecosPage,
     // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
    public  toast          : ToastController,
     // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
    private geolocation    : Geolocation,
    public  modalController: ModalController,
  ) {
    this.init();
  }

  ngOnInit() {
    // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
    this.actRouter.params.subscribe((data:any)=>{
      this.id_cliente = data.id;
    });



  }
  async ionViewWillEnter() {

    this.endereco        = '';
    this.data            = '';
    this.cep             = '';
    this.logradouro      = '';
    this.complemento     = '';
    this.bairro          = '';
    this.localidade      = '';
    this.uf              = '';
    this.ibge            = '';
    this.gia             = '';
    this.ddd             = '';
    this.siafi           = '';
    this.referencia      = '';

    this.endereco_BUSCA  = [];

    const name = await this.storage.get('DADOS_CEP');
    if (this.isString(name)) {
      // eslint-disable-next-line id-blacklist
      this.string   = true;
      this.endereco =  name;


    } else {

      // eslint-disable-next-line id-blacklist
      this.string = false;
      this.endereco_BUSCA = name;

    }

    this.lisatr();







  }
   isString(value: any): value is string {
    // eslint-disable-next-line @typescript-eslint/quotes
    return typeof value === "string" || value instanceof String;
  }

  separateAddress(address: string): Endereco {
    const regex = /^(.*?),\s*(\d+)\s*(?:-\s*(.*?))?\s*,\s*(.*?)\s*-\s*(\w{2})\s*,\s*(\d{5}-\d{3})\s*(?:,\s*(.*))?$/;
    const match = address.match(regex);
    if (!match) {
      // eslint-disable-next-line prefer-const
      let navigationExtras: NavigationExtras = {
        state: {


          endereco       : '' ,
          cep            : '',
          logradouro     : '' ,
          complemento    : '' ,
          bairro         : '' ,
          localidade     : '' ,
          uf             : '',
          ibge           : 0,
          referencia     : '',
          id             : this.id_cliente,
          numero         : '',



        }};


        this.OpenModalCadastro(navigationExtras);
    }
    const [, endereco, numero, bairro, cidade, estado, cep, pais] = match;
    return { endereco: `${endereco}, ${numero}${bairro ? ` - ${bairro}` : ''}`, numero, bairro, cidade, estado, cep, pais };
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/type-annotation-spacing
  Desmenbrar(endereco : any){
   this.dados =  this.separateAddress(endereco);

    // enviar parametros
 // eslint-disable-next-line prefer-const
 let navigationExtras: NavigationExtras = {
  state: {


    endereco       : '' ,
    cep            : this.dados.cep,
    logradouro     : this.dados.endereco ,
    complemento    : this.dados.complemento ,
    bairro         : this.dados.bairro ,
    localidade     : this.dados.cidade ,
    uf             : this.dados.estado,
    ibge           : 0,
    referencia     : this.dados.bairro,
    id             : this.id_cliente,
    numero         : this.dados.numero,



  }};
  this.OpenModalCadastro(navigationExtras);
  }



  async lisatr(){


     await this.storage.get('DADOS_CEP').then
     (
      resp => this.logradouro = resp.logradouro as   []
      );
     await this.storage.get('DADOS_CEP').then
     (
      resp => this.cep = resp.cep as                 []
      );
     await this.storage.get('DADOS_CEP').then
     (
      resp => this.complemento = resp.complemento as []
      );
     await this.storage.get('DADOS_CEP').then
     (
      resp => this.bairro = resp.bairro as           []
      );
     await this.storage.get('DADOS_CEP').then
     (
      resp => this.localidade = resp.localidade as   []
      );
     await this.storage.get('DADOS_CEP').then
     (
      resp => this.uf = resp.uf as                   []
     );
      await this.storage.get('DADOS_CEP').then
     (
       resp => this.ibge = resp.ibge as              []
     );



     // eslint-disable-next-line eqeqeq
     if(this.cep == undefined)
     {
      this.nao_sei_cep = false;
      this.busca_cep   = true;
     }
     else
     {
      this.nao_sei_cep = true;
      this.busca_cep   = false;
     }








  }






  voltar(){
   this.modalController.dismiss();
  }
 cadastro(

   endereco ,
   cep,
   logradouro ,
   bairro ,
   localidade ,
   uf,
   ibge ,
   referencia ,
   complemento
 )
 {

 // enviar parametros
 // eslint-disable-next-line prefer-const
 let navigationExtras: NavigationExtras = {
  state: {


    endereco       : '' ,
    // eslint-disable-next-line object-shorthand
    cep            : cep,
    // eslint-disable-next-line object-shorthand
    logradouro     : logradouro ,
    // eslint-disable-next-line object-shorthand
    complemento    : complemento ,
    // eslint-disable-next-line object-shorthand
    bairro         : bairro ,
    // eslint-disable-next-line object-shorthand
    localidade     : localidade ,
    // eslint-disable-next-line object-shorthand
    uf             : uf,
    // eslint-disable-next-line object-shorthand
    ibge           : ibge,
    // eslint-disable-next-line object-shorthand
    referencia     : referencia,
    id             : this.id_cliente



  }};
  this.OpenModalCadastro(navigationExtras);

 }




// eslint-disable-next-line @typescript-eslint/naming-convention, space-before-function-paren
CADASTRO_ENDERECO
(
  // eslint-disable-next-line @typescript-eslint/naming-convention
  CEP,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  LOGRADOURO,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  NUMERO,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  CIDADE,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  BAIRRO,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  COMPLEMENTO,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  REFERENCIA,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  CLIENTE
  )

{
  this.usuario.getcadastroENDERECO
  (
    CEP,
    LOGRADOURO,
    NUMERO,
    CIDADE,
    BAIRRO,
    COMPLEMENTO,
    REFERENCIA,
    CLIENTE,
    this.LOJA.EMPRESA,
    this.localidade
    ).subscribe(async data =>
    {
      // eslint-disable-next-line no-var, @typescript-eslint/dot-notation
      var alert   = data['MSG'];
     const toast   = await this.toast.create({

         message : alert,
         duration: 4000,
         color   : 'danger'
      });
     toast.present();


    }

    );

  this.home(this.id_cliente);

}


home(id) {
  this.modalController.dismiss();
  this.pagnaEndereco.ionViewWillEnter();
}

  async init() {

    const storage = await this.storage.create();
    // eslint-disable-next-line no-underscore-dangle
    this._storage = storage;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  async OpenModalCadastro(navigationExtras){
    this.modalController.dismiss();
    const modal = await this.modalController.create({
      component            : CadastroEnderecoPage,
      cssClass             : 'ion-modal',
      componentProps       : {
        // eslint-disable-next-line object-shorthand
        navigationExtras   : navigationExtras,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        LOJA               : this.LOJA,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        PGenderecos_cad    : this,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        PGbuscaCep         : this.PGbuscaCep,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Pgenderecos        : this.Pgenderecos
      }
    });
    await modal.present();
  }

}
