/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ServidorService } from 'src/app/servidor.service';



// retorno login
export interface usuario{
  // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
  CLIENTE  : string;
  // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
  RAZAO    : string;
  // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
  SENHA    : string;
  };

  // retorno login
export interface historico{
  // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/member-delimiter-style
  PEDIDO       : string,
  // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/member-delimiter-style
  STATUS       : string,
  // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/member-delimiter-style
  OBS          : string,
  // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/member-delimiter-style
  EMISSAO      : string,
// eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/member-delimiter-style
  RSTATUS0     : string,
  // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/member-delimiter-style
  RSTATUS1     : string,
  // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/member-delimiter-style
  RSTATUS2     : string,
  // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/member-delimiter-style
  RSTATUS3     : string,
  // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/member-delimiter-style
  RSTATUS4     : string,
  // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/member-delimiter-style
  HORA         : string;
  // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/member-delimiter-style
  CAMPO02      : string;
  // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/member-delimiter-style
  CAMPO01      : string;
  // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/member-delimiter-style
  CAMPO03      : string;
  // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/member-delimiter-style
  CAMPO04      : string;
  // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/member-delimiter-style
  TIPO         : string;
  // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/member-delimiter-style
  SITUACAO     : String;
  // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/member-delimiter-style
  ENTREGAR     : Date;
  // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/member-delimiter-style
  HORA_ENTREGA : string;
  // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/member-delimiter-style
  VALOR        : string;
  // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/member-delimiter-style
  TROCO        : Number;
  };

  export interface MEUSDADOS{
    // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/member-delimiter-style
    NFE_EMAILI         : string;
    // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/member-delimiter-style
    NASCIMENTO         : string;
    // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/member-delimiter-style
    FANTASIA           : string;
    // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/member-delimiter-style
    RAZAO              : string;
    // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/member-delimiter-style
    CLIENTE            : string;
    // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/member-delimiter-style
    FONEI              : string;
    // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/member-delimiter-style
    CPF                : string;
    // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/member-delimiter-style
    RG                 : string;
    // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/member-delimiter-style
    SENHA              : string;
  }

  // retorno ENDERECO
export interface ENDERECO{
// eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/member-delimiter-style
            NOME_CIDADE  : string;
            // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/member-delimiter-style
            LOGRADOURO   : string;
            // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/member-delimiter-style
            NUMERO       : string;
            // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/member-delimiter-style
            BAIRRO       : string;
            // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/member-delimiter-style
            COMPLEMENTO  : string;
            // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/member-delimiter-style
            DISTANCIA    : string;
            // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/member-delimiter-style
            REFERENCIA   : string;
            // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/member-delimiter-style
            ENDERECO     : string;
            // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/member-delimiter-style
            CIDADE       : string;
            // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/member-delimiter-style
            CEP          : string;
            // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/member-delimiter-style
            CLIENTE      : string;
  };

 export interface resumo{
  // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/member-delimiter-style
  ITEM            : string;
  // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/member-delimiter-style
  VALOR           : string;
  // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/member-delimiter-style
  QTD             : string;
  // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/member-delimiter-style
  TOTAL           : string;
  // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/member-delimiter-style
  NOME_PRODUTO    : string;
  // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/member-delimiter-style
  tipo            : string;
 }

 export interface TEXTO{
  // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
  texto            : string;

 }



  export interface dados{
         // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
         MSG : String;

};
@Injectable({
  providedIn: 'root'
})



export class UsuarioService {
  // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
  USUARIO    :  string;

  constructor(
    // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
    private http    : HttpClient,
    // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
    private config  : ServidorService,

    ) { }


 // listar USUARIO
 // eslint-disable-next-line @typescript-eslint/naming-convention
 getLogin(login,login_senha,Google,Consumidor,PEDIDO,EMPRESA){

  return this.http.get<[usuario]>(this.config.baseUrl + '/LOGIN?CLIENTE='+login+'&SENHA='+login_senha+'&Google='+Google+'&CONSUMIDOR='+Consumidor+'&NPED='+PEDIDO+'&empresa='+EMPRESA);
}

// listar USUARIO
// eslint-disable-next-line @typescript-eslint/naming-convention
getusuario(CLIENTE){

  return this.http.get<[usuario]>(this.config.baseUrl + '/USUARIO?CLIENTE='+CLIENTE);
}
// listar ENDEREÇOS
// eslint-disable-next-line @typescript-eslint/naming-convention
getENDERECO(login,EMPRESA){

  return this.http.get<[ENDERECO]>(this.config.baseUrl + '/ENDERECO?CLIENTE='+login+'&EMPRESA='+EMPRESA);
}

// deletar ENDEREÇO
// eslint-disable-next-line @typescript-eslint/naming-convention
getdeleteENDERECO(id,CLIENTE){

  return this.http.get<[ENDERECO]>(this.config.baseUrl + '/DELETE_ENDERECO?id='+id+'&CLIENTE='+CLIENTE);
}

// CADASTRAR ENDEREÇO
// eslint-disable-next-line @typescript-eslint/naming-convention
getcadastroENDERECO(CEP, LOGRADOURO, NUMERO, CIDADE, BAIRRO, COMPLEMENTO, REFERENCIA, CLIENTE,EMPRESA,DESC_CID){

  return this.http.get<[dados]>(this.config.baseUrl + '/CADASTRO_ENDERECO?CEP='+CEP+'&LOGRADOURO='+LOGRADOURO+'&DESC_CIDADE='+DESC_CID+'&NUMERO='+NUMERO+'&CIDADE='+CIDADE+'&BAIRRO='+BAIRRO+'&COMPLEMENTO='+COMPLEMENTO+'&REFERENCIA='+REFERENCIA+'&CLIENTE='+CLIENTE+'&EMPRESA='+EMPRESA);
}

// CADASTRAR ENDEREÇO
// eslint-disable-next-line @typescript-eslint/naming-convention
Contato(email,telefone,razao,cnpj){

  return this.http.get<[dados]>(this.config.baseUrl + '/CONTATO?email='+email+'&telefone='+telefone+'&razao='+razao+'&cnpj='+cnpj);
}




// ATUALIZAR  DADOS
// eslint-disable-next-line @typescript-eslint/naming-convention
getATUALIZARDADOS(EMAIL, CLIENTE, NASCIMENTO, FANTASIA, FONEI, CPF, RG, SENHA){

  return this.http.get<[dados]>(this.config.baseUrl + '/ATUALIZARmeusDados?EMAIL='+EMAIL+'&CLIENTE='+CLIENTE+'&NASCIMENTO='+NASCIMENTO+'&FANTASIA='+FANTASIA+'&FONEI='+FONEI+'&CPF='+CPF+'&RG='+RG+'&SENHA='+SENHA);
}

// ATUALIZAR  DADOS
// eslint-disable-next-line @typescript-eslint/naming-convention
getRECUPERAR(EMAIL){

  return this.http.get<[TEXTO]>(this.config.baseUrl + '/REUPERAR?EMAIL='+EMAIL);
}


// CADASTRAR USUARIO
// eslint-disable-next-line space-before-function-paren
getcadastroUsuario
 (
  // eslint-disable-next-line @typescript-eslint/naming-convention
  NOME : String,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  TELEFONE,
   )
  {

  return this.http.get<[dados]>

  (this.config.baseUrl + '/CADASTRO?NOME='+NOME+'&TELEFONE='+TELEFONE);
  }
  // histórico do usuario
// eslint-disable-next-line @typescript-eslint/naming-convention
gethistorico(CLIENTE){

  return this.http.get<[historico]>(this.config.baseUrl + '/HISTORICO?CLIENTE='+CLIENTE);
}
// eslint-disable-next-line @typescript-eslint/naming-convention
status(CLIENTE){

  return this.http.get<[historico]>(this.config.baseUrl + '/status?CLIENTE='+CLIENTE);
}

// eslint-disable-next-line @typescript-eslint/naming-convention
MEUSDADOS(CLIENTE){

  return this.http.get<[MEUSDADOS]>(this.config.baseUrl + '/meusDados?CLIENTE='+CLIENTE);
}

  // histórico do usuario
  // eslint-disable-next-line @typescript-eslint/naming-convention
  item_pedidos(CLIENTE,PEDIDO){

    return this.http.get<[resumo]>(this.config.baseUrl + '/RESUMO_PEDIDO?CLIENTE='+CLIENTE+'&PEDIDO='+PEDIDO);
  }





}
